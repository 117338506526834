import styled from 'styled-components';

import {
  defaultBorderColorRed,
  defaultBorderColorTurquoise,
  defaultCheckboxBorderRadius,
  defaultBtColorDarkGreen,
  flexCenterAlignments,
} from '../mainConstants';

export const Checkbox = styled.div(({ checked, isError }) => ({
  width: '13px',
  height: '13px',
  display: 'flex',
  flexCenterAlignments,
  border: `2px solid ${isError ? defaultBorderColorRed : defaultBorderColorTurquoise}`,
  'border-radius': defaultCheckboxBorderRadius,
  'background-color': checked ? 'transparent' : defaultBtColorDarkGreen,
}));

export const fake = 'fake';
