import { createRoutine } from 'redux-saga-routines';
import { put, call } from 'redux-saga/effects';

import { redditBestService } from '~/api/testAPI';
import API from '~/api/api';
import utilsConstants from '~/utils/constants';

import { setNotification } from '../notifications/notifications.action';

const { testRoute } = API;

const {
  defaultErrorMessage,
  toasters: { typeSuccess, typeError },
} = utilsConstants;

export const setLanguage = createRoutine('SET_LANGUAGE');

export const getSuccessNotification = (lng) => ({
  active: true,
  type: typeSuccess.name,
  title: 'Language changed',
  description: `You set ${lng}`,
});
export const getFailureNotification = (message) => ({
  active: true,
  type: typeError.name,
  title: message || defaultErrorMessage,
});

export function* sagaOnSetLanguage({ payload }) {
  try {
    localStorage.setItem('lng', payload.shortName);
    // yield redditBestService(testRoute);
    yield call(redditBestService, testRoute);
    // TODO: make for testing notifications, remove after testing
    const notificationData = getSuccessNotification(payload.shortName);
    yield put(setNotification.success(notificationData));
    yield put(setLanguage.success(payload));
  } catch (e) {
    const notificationData = getFailureNotification(e?.message);
    yield put(setNotification.failure(notificationData));
  }
}
