import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContainerValues } from '~/ServiceContainer';

import { SlowOnset } from '~/styledComponents/effects/SlowOnset';

import {
  defaultBtColorTurquoise,
  defaultBtColorDarkBlue,
  flexCenterAlignments,
} from '../mainConstants';

const {
  icons: {
    evaiconsOutline: { ArrowIosBackOutline },
  },
} = ContainerValues;

const BackBtIcon = styled(ArrowIosBackOutline)``;

const BackBtWrapper = styled.div`
  cursor: pointer;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  ${flexCenterAlignments};
  padding: ${({ padding }) => padding}px;
  margin-bottom: 10vh;
  border-radius: ${({ size }) => size}px;
  background-color: ${({ bgColor }) => bgColor};
`;

/**
 * BackButton
 * @param {boolean} isFill
 * @param {number} size
 * @param {string} color - HEX
 * @param {boolean} isFade
 * @param {func} onAnimationEnd
 * @param {number} padding
 * @param {string} bgColor - HEX
 * @param {func} onPress
 */
const BackButton = ({ isFill, size, color, isFade, onAnimationEnd, padding, bgColor, onPress }) => (
  <SlowOnset noBlink={!isFade} timing="0.3s">
    <BackBtWrapper padding={padding} bgColor={bgColor} size={size} onClick={onPress}>
      <BackBtIcon size={size} color={color} isFill={isFill} onAnimationEnd={onAnimationEnd} />
    </BackBtWrapper>
  </SlowOnset>
);
BackButton.propTypes = {
  isFill: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  isFade: PropTypes.bool,
  onAnimationEnd: PropTypes.func,
  padding: PropTypes.number,
  bgColor: PropTypes.string,
  onPress: PropTypes.func,
};
BackButton.defaultProps = {
  isFill: false,
  size: 12,
  color: defaultBtColorDarkBlue,
  isFade: false,
  onAnimationEnd: () => {},
  padding: 5,
  bgColor: defaultBtColorTurquoise,
  onPress: () => {},
};

export default BackButton;
