// Це два сусідніх контейнера в DOM
// const appRoot = document.getElementById('root');

import React, { useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';

// eslint-disable-next-line import/no-cycle
import { ErrorBoundary } from '~/components';
import { SlowOnset } from '~/styledComponents/effects/SlowOnset';

const modalRoot = document.getElementById('info_modal');

const ModalsPortal = ({ modal }) => {
  const el = document.createElement('div');
  el.className = 'wrapperModals';
  useEffect(() => {
    modalRoot.appendChild(el);
    return () => modalRoot.removeChild(el);
  });

  const renderMemoizedModal = useCallback(
    () => (
      <ErrorBoundary>
        <SlowOnset timing="0.15s">{modal}</SlowOnset>
      </ErrorBoundary>
    ),
    [modal]
  );

  return createPortal(renderMemoizedModal(), el);
};

export default ModalsPortal;
