import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import configureStore from '~/redux/configureStore';
import rootSaga from '~/redux/sagas';

import { ErrorBoundary } from '~/components';
import { ContainerContextProvider, ContainerValues } from '~/ServiceContainer';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

import * as serviceWorker from './serviceWorker';

const {
  notistack: { SnackbarProvider },
  utilsConstants: { localStorageKeys, defaultLanguage },
} = ContainerValues;

const store = configureStore();
store.subscribe(() => {
  const serializedState = JSON.stringify(store.getState());
  localStorage.setItem(localStorageKeys.store, serializedState);
});
store.runSaga(rootSaga);

const root = document.getElementById('root');
localStorage.setItem('lng', defaultLanguage.shortName);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ContainerContextProvider>
        <ErrorBoundary>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            autoHideDuration={3500}
            preventDuplicate
          >
            <App />
          </SnackbarProvider>
        </ErrorBoundary>
      </ContainerContextProvider>
    </Provider>
  </React.StrictMode>,
  root || document.createElement('div')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
