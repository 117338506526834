import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '~/styledComponents/atoms';

/**
 * Input React Hook Form
 * @property {object} register
 * @property {string} name
 * @property {object} formState
 */
const InputRHF = ({ register, name, formState, testID, ...rest }) => {
  const isError = formState?.errors[name];
  const updatedTestID = isError ? `${testID}-error` : testID;
  return <Input {...register(name)} {...rest} isError={isError} data-testid={updatedTestID} />;
};
InputRHF.propTypes = {
  register: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  formState: PropTypes.object,
  name: PropTypes.string.isRequired,
  testID: PropTypes.string.isRequired,
};
InputRHF.defaultProps = {
  register: () => {},
  formState: {},
};

export default InputRHF;
