import styled from 'styled-components';

import { defaultFontColorDarkBlue, displayBlock, flexDirectionColumn } from '../mainConstants';

export const Label = styled.label(
  ({
    color = defaultFontColorDarkBlue,
    display = displayBlock,
    flexDirection = flexDirectionColumn,
  }) => ({
    display,
    'flex-direction': flexDirection,
    color,
  })
);

export const fake = 'fake';
