/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { ContainerContext } from '~/ServiceContainer';

import CheckboxWithText from '~/styledComponents/molecules/CheckboxWithText';

/**
 * Checkbox React Hook Form
 * @property {object} control
 * @property {string} name
 * @property {object} formState
 */
const CheckboxRHF = ({ control, name, formState, description, testID, ...rest }) => {
  const {
    reactHookForm: { Controller },
  } = useContext(ContainerContext);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        const handleOnChange = () => onChange(!value); // send value to hook form
        const isError = !!formState?.errors[name];
        const updatedTestID = isError ? `${testID}-error` : testID;
        return (
          <CheckboxWithText
            value={value}
            description={description}
            onClick={handleOnChange}
            onBlur={onBlur}
            isError={isError}
            checkboxRef={ref}
            ref={ref}
            data-testid={updatedTestID}
            {...rest}
          />
        );
      }}
    />
  );
};

CheckboxRHF.propTypes = {
  control: PropTypes.object,
  formState: PropTypes.object,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  testID: PropTypes.string.isRequired,
};

CheckboxRHF.defaultProps = {
  control: {},
  formState: {},
  description: 'Remember me',
};

export default CheckboxRHF;
