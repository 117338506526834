import React, { memo, forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSnackbar, SnackbarContent } from 'notistack';

import { ContainerValues } from '~/ServiceContainer';

import { Text } from '~/styledComponents/atoms';

import {
  defaultBGColorGrey,
  defaultBtBorderRadius,
  defaultBtPadding,
  flexCenterAlignments,
  defaultBGColorWhite,
  defaultFontColorLightGrey,
  defaultIconColorLightBlue,
  defaultIconColorDarkBlue,
  defaultIconColorYellow,
  defaultIconColorDarkYellow,
  defaultIconColorLightGreen,
  defaultIconColorDarkGreen,
  defaultIconColorLightPink,
  defaultIconColorLightRed,
  defaultIconColorRed,
} from '../mainConstants';

const {
  icons: {
    ioniconsOutline: { Warning, CheckmarkCircle },
    evaiconsOutline: { InfoOutline, CloseOutline },
  },
  utilsConstants: {
    toasters: { typeInfo, typeWarning, typeError, typeSuccess, typeInputError },
  },
} = ContainerValues;

const typeColors = {
  [typeInfo.name]: {
    bg: defaultIconColorLightBlue,
    icon: defaultIconColorDarkBlue,
    size: 17,
  },
  [typeWarning.name]: {
    bg: defaultIconColorYellow,
    icon: defaultIconColorDarkYellow,
    size: 20,
  },
  [typeError.name]: {
    bg: defaultIconColorLightPink,
    icon: defaultIconColorRed,
    size: 20,
  },
  [typeSuccess.name]: {
    bg: defaultIconColorLightGreen,
    icon: defaultIconColorDarkGreen,
    size: 20,
  },
  [typeInputError.name]: {
    bg: defaultIconColorRed,
    closeIconBg: defaultIconColorLightRed,
  },
};

const IconWrapper = styled.div(({ size = 30, bgColor }) => ({
  width: `${size}px`,
  height: `${size}px`,
  display: 'flex',
  flexCenterAlignments,
  'border-radius': `${size / 2}px`,
  'background-color': bgColor || defaultIconColorLightBlue,
}));

const CloseIcon = styled(CloseOutline)`
  border: ${({ border }) => border || `1px solid ${defaultBGColorGrey}`};
  padding: 3px;
  border-radius: ${({ size }) => size}px;
`;

const InfoIcon = styled(InfoOutline)`
  border-radius: ${({ size }) => size / 2}px;
  color: ${({ color }) => color || defaultIconColorDarkBlue};
`;

const WarningIcon = styled(Warning)`
  border-radius: ${({ size }) => size / 2}px;
  color: ${({ color }) => color || defaultIconColorDarkYellow};
`;

const SuccessIcon = styled(CheckmarkCircle)`
  border-radius: ${({ size }) => size / 2}px;
  color: ${({ color }) => color || defaultIconColorDarkGreen};
`;

const Wrapper = styled.div(({ padding, borderRadius, fontWeight, bgColor, textColor, margin }) => ({
  width: '100%',
  maxWidth: '320px',
  height: '30px',
  display: 'flex',
  flexCenterAlignments,
  'justify-content': 'space-between',
  padding: padding || defaultBtPadding,
  margin: margin || '0px',
  'border-radius': borderRadius || defaultBtBorderRadius,
  'background-color': bgColor || 'rgba(19, 40, 70, 0.6)',
  'font-weight': fontWeight || '700',
  color: textColor || defaultBGColorWhite,
}));

const ContentWrapper = styled.div`
  flex: 1;
  display: 'flex';
  flex-direction: 'column';
  padding: 0 10px;
`;

const OneRowText = styled(Text)`
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  box-orient: vertical;
  overflow: hidden; /* Обрезаем все, что не помещается в область */
  padding: ${(padding) => padding || 0}px;
`;

const LeftIcon = memo(({ type, size }) => {
  switch (type) {
    case typeInfo.name:
      return <InfoIcon size={size} color={typeColors[type].icon} />;
    case typeWarning.name:
      return <WarningIcon size={size || typeColors[type].size} color={typeColors[type].icon} />;
    case typeError.name:
      return <WarningIcon size={size || typeColors[type].size} color={typeColors[type].icon} />;
    case typeSuccess.name:
      return <SuccessIcon size={size} color={typeColors[type].icon} />;
    default:
      return <InfoIcon size={size} color={typeColors[type].icon} />;
  }
});
LeftIcon.propTypes = {
  type: PropTypes.string,
  size: PropTypes.number,
};
LeftIcon.defaultProps = {
  type: typeInfo.name,
  size: 17,
};

const Common = memo(({ type, id, title, description, handleDismiss }) => (
  <Wrapper id={id}>
    <IconWrapper bgColor={typeColors[type].bg}>
      <LeftIcon type={type} size={typeColors[type].size} />
    </IconWrapper>
    <ContentWrapper>
      <OneRowText>{title}</OneRowText>
      {description && (
        <OneRowText color={defaultFontColorLightGrey} padding="3px 0px 0px 0px">
          {description}
        </OneRowText>
      )}
    </ContentWrapper>
    <CloseIcon size={15} onClick={handleDismiss} />
  </Wrapper>
));
Common.propTypes = {
  type: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  handleDismiss: PropTypes.func,
};
Common.defaultProps = {
  type: typeInfo.name,
  description: null,
  handleDismiss: () => {},
};

const InputErrorSnackbar = memo(({ type, id, title, handleDismiss }) => (
  <Wrapper id={id} bgColor={typeColors[type].bg}>
    <ContentWrapper>
      <OneRowText>{title}</OneRowText>
      {/* TODO: remove if don't need in some time 11.07.22 */}
      {/* {description && <OneRowText color={defaultFontColorLightGrey}>{description}</OneRowText>} */}
    </ContentWrapper>
    <IconWrapper bgColor={typeColors[type].closeIconBg}>
      <CloseIcon size={15} onClick={handleDismiss} border="none" />
    </IconWrapper>
  </Wrapper>
));
InputErrorSnackbar.propTypes = {
  type: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  handleDismiss: PropTypes.func,
};
InputErrorSnackbar.defaultProps = {
  type: typeInfo.name,
  handleDismiss: () => {},
};

/**
 * Snackbar
 * @param {string} [type] - Info | Warning | Error | Success | InputError | Undo.
 * @default typeInfo.name
 * @param {*} [children] - children.
 * @param {string} id - id.
 * @param {string} title - Notifications title.
 * @param {string} [description] - Notifications description.
 */
const Snackbar = forwardRef(({ type, children, id, title, description }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);
  if (children) {
    return (
      <SnackbarContent ref={ref}>
        <Wrapper id={id}>{children}</Wrapper>
      </SnackbarContent>
    );
  }
  switch (type) {
    case typeInputError.name:
      return (
        <SnackbarContent ref={ref}>
          <InputErrorSnackbar type={type} id={id} title={title} handleDismiss={handleDismiss} />
        </SnackbarContent>
      );
    default:
      return (
        <SnackbarContent ref={ref}>
          <Common
            type={type}
            id={id}
            title={title}
            description={description}
            handleDismiss={handleDismiss}
          />
        </SnackbarContent>
      );
  }
});
Snackbar.propTypes = {
  type: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};
Snackbar.defaultProps = {
  type: typeInfo.name,
  description: null,
};

export default Snackbar;
