import { handleActions } from 'redux-actions';

import { ContainerValues } from '~/ServiceContainer';

import { setNotification } from '~/redux/actions/notifications/notifications.action';

const {
  helpers: { DateHelper },
} = ContainerValues;

export const initialStore = {
  active: false, // true | false
  type: 'Info', // type: string, value: Info | Warning | Error | Success | InputError | Undo.
  displayType: 'system', // displayType: string, value: System | Toaster | Tooltip | Undo
  priority: 'high', // priority: string, value: high || low
  layout: 'logoAction', // priority: string, value: logo || action || logoAction
  title: 'Test', // type: string, value: 'any'
  description: null, // type: string, value: 'any'
  // status: false, // type: boolean, value: true | false
  timestamp: null, // unix timestamp
  items: [],
  lastDisplayed: null,
};

export default handleActions(
  {
    [setNotification.SUCCESS](state, action) {
      const { active, type, title, description } = action.payload;
      const newNotification = active
        ? {
            active,
            type,
            title: title || 'Test Error Title',
            description: description ?? null,
            timestamp: DateHelper.getUnixTimestamp(),
          }
        : { ...state, active };
      return newNotification;
    },
    [setNotification.FAILURE](state, action) {
      const { active, type, title, description } = action.payload;
      const newNotification = active
        ? {
            active,
            type,
            title: title || 'Test Error Title',
            description: description ?? null,
            timestamp: DateHelper.getUnixTimestamp(),
          }
        : { ...state, active };
      return newNotification;
    },
  },
  initialStore
);

// SELECTORS
export const getNotifications = (store) => store.notifications;

// For using >1 data object use like this:
// import { combineReducers } from 'redux';
// export default combineReducers({
//   data,
// });
