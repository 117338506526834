import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ContainerValues } from '~/ServiceContainer';

import RootReducer from './reducers/rootReducer.reducer';
import middlewareErrorHandler from './middlewareErrorHandler';

const {
  utilsConstants: { localStorageKeys },
  Bugsnag,
  lodash,
  helpers: { ObjectHelper },
} = ContainerValues;

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware({
    sagaMonitor: {
      // effectTriggered: (options) => console.log('effectTriggered label', options),
      // effectResolved: (effectId, result) => console.log('Saga effectResolved', effectId, result),
      // actionDispatched: (action) => console.warn('actionDispatched label', action),
    },
    onError: (error, { sagaStack }) =>
      Bugsnag.notify(error, function (event) {
        event.addMetadata('ErrorDetails', { sagaStack });
      }),
  });

  const middleware = [sagaMiddleware, middlewareErrorHandler];
  const cachedStore = JSON.parse(localStorage.getItem(localStorageKeys.store)) || {};

  const initialStore = ObjectHelper.changeObjectProps(lodash.cloneDeep(cachedStore));

  let updatedMiddleware;
  // uncomment if need some specific modules not in production
  if (process.env.NODE_ENV !== 'production') {
    // const c = require('some-debug-middleware')
    // const d = require('another-debug-middleware')
    // middleware = [...middleware, c, d]
    updatedMiddleware = composeWithDevTools(applyMiddleware(...middleware));
  } else {
    updatedMiddleware = applyMiddleware(...middleware);
  }
  return {
    ...createStore(RootReducer, initialStore, updatedMiddleware),
    runSaga: sagaMiddleware.run,
  };
}
