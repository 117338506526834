import { combineReducers } from 'redux';

import common from './common/common.reducer';
import notifications from './notifications.reducer';
import loading from './loading/loading.reducer';
import user from './user/user.reducer';

export default combineReducers({
  common,
  notifications,
  loading,
  user,
});
