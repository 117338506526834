const API = {
  user: {
    login: 'user/login',
    signup: '/user/signu',
    delete: '/user',
    logout: '/user/logout',
    resetPassword: '/user/reset-password',
    forgoPassword: '/user/forgot-password',
    restorePassword: '/user/restore-password',
    checkEmail: '/user/email',
  },
  testRoute: '/r/best',
};

export default API;
