import { handleActions } from 'redux-actions';

import { ContainerValues } from '~/ServiceContainer';

import {
  // with requests
  changeAuth,
  login,
  signUp,
  logout,
  resetPassword,
  forgotPassword,
  restorePassword,
  checkEmail,
  // without requests
  updateUserOnboarding,
  updateUserProfile,
} from '~/redux/actions/user/user.action';

const {
  helpers: { ObjectHelper },
} = ContainerValues;

export const initialState = {
  auth: false,
  onboarding: {},
  profile: {},
  isLoading: false,
  error: { status: false, message: '' },
};

export default handleActions(
  {
    // with requests
    [login.TRIGGER](state) {
      return { ...state, isLoading: true };
    },
    [login.SUCCESS](state, action) {
      return {
        ...state,
        isLoading: false,
        auth: true,
        profile: action.payload,
      };
    },
    [login.FAILURE](state, action) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    [signUp.TRIGGER](state) {
      return { ...state, isLoading: true };
    },
    [signUp.SUCCESS](state, action) {
      return {
        ...state,
        isLoading: false,
        onboarding: {},
        profile: action.payload,
      };
    },
    [signUp.FAILURE](state, action) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    [logout.TRIGGER](state) {
      return { ...state, isLoading: true };
    },
    [logout.SUCCESS](state) {
      return {
        ...state,
        isLoading: false,
        auth: false,
        profile: {
          firstName: state.profile.firstName,
          email: state.profile.email,
          rememberMe: state.profile.rememberMe,
        },
      };
    },
    [logout.FAILURE](state, action) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    [resetPassword.TRIGGER](state) {
      return { ...state, isLoading: true };
    },
    [resetPassword.SUCCESS](state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    [resetPassword.FAILURE](state, action) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    [forgotPassword.TRIGGER](state) {
      return { ...state, isLoading: true };
    },
    [forgotPassword.SUCCESS](state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    [forgotPassword.FAILURE](state, action) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    [restorePassword.TRIGGER](state) {
      return { ...state, isLoading: true };
    },
    [restorePassword.SUCCESS](state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    [restorePassword.FAILURE](state, action) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    [checkEmail.TRIGGER](state) {
      return { ...state, isLoading: true };
    },
    [checkEmail.SUCCESS](state) {
      return {
        ...state,
        isLoading: false,
      };
    },
    [checkEmail.FAILURE](state, action) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    // without requests
    [changeAuth.SUCCESS](state, action) {
      const { auth } = action.payload;
      return {
        ...state,
        auth,
      };
    },
    [updateUserOnboarding.SUCCESS](state, action) {
      const onboarding = ObjectHelper.isEmptyObj(action.payload)
        ? {}
        : {
            ...state.onboarding,
            ...action.payload,
          };
      return {
        ...state,
        onboarding,
      };
    },
    [updateUserProfile.SUCCESS](state, action) {
      const profile = ObjectHelper.isEmptyObj(action.payload)
        ? {}
        : {
            ...state.profile,
            ...action.payload,
          };
      return {
        ...state,
        profile,
      };
    },
  },
  initialState
);

// For using >1 data object use like this:
// import { combineReducers } from 'redux';
// export default combineReducers({
//   data,
// });
