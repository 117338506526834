class EncodeDecodeHelper {
  constructor(props) {
    this.asciiAverageQuantity = props?.asciiAverageQuantity || 79;
    this.dif = props?.dif || 4;
    this.encodeStringAlgorithm = this.encodeStringAlgorithm.bind(this);
    this.decodeStringAlgorithm = this.decodeStringAlgorithm.bind(this);
    this.encodeBase64buffer = this.encodeBase64buffer.bind(this);
    this.decodeBase64buffer = this.decodeBase64buffer.bind(this);
  }

  encodeStringAlgorithm(string) {
    console.log(`email: ${string}`);
    if (!string || typeof string !== 'string') throw new Error('Wrong params');
    const stringLength = string.length;
    let i = 0;
    let encodedString = '';
    while (i < stringLength) {
      const asciiValue = string.codePointAt(i);
      // const dif = Math.ceil(asciiValue / getSumOfDigits(asciiValue));
      let newAsciiValue;
      if (asciiValue > this.asciiAverageQuantity) {
        newAsciiValue = asciiValue - this.dif;
      } else {
        newAsciiValue = asciiValue + this.dif;
      }
      const newCharacter = String.fromCharCode(newAsciiValue);
      encodedString += newCharacter;
      i += 1;
    }
    return encodedString;
  }

  decodeStringAlgorithm(string) {
    if (!string || typeof string !== 'string') throw new Error('Wrong params');
    const stringLength = string.length;
    let i = 0;
    let decodedString = '';
    while (i < stringLength) {
      const asciiValue = string.codePointAt(i);
      // const dif = Math.ceil(asciiValue / getSumOfDigits(asciiValue));
      let newAsciiValue;
      if (asciiValue > this.asciiAverageQuantity - this.dif) {
        newAsciiValue = asciiValue + this.dif;
      } else {
        newAsciiValue = asciiValue - this.dif;
      }
      const newCharacter = String.fromCharCode(newAsciiValue);
      decodedString += newCharacter;
      i += 1;
    }
    return decodedString;
  }

  // const x = 79;
  // const dif = 4;
  // const encodeStringAlgorithm = (string) => {
  //   console.log(`email: ${string}`);
  //   if (!string || typeof string !== 'string') throw new Error('Wrong params');
  //   const stringLength = string.length;
  //   let i = 0;
  //   let encodedString = '';
  //   while (i < stringLength) {
  //     // const character = string[i];
  //     const asciiValue = string.codePointAt(i);
  //     // const dif = Math.ceil(asciiValue / getSumOfDigits(asciiValue));
  //     let newAsciiValue;
  //     if (asciiValue > x) {
  //       newAsciiValue = asciiValue - dif;
  //     } else {
  //       newAsciiValue = asciiValue + dif;
  //     }
  //     const newCharacter = String.fromCharCode(newAsciiValue);
  //     encodedString += newCharacter;
  //     // eslint-disable-next-line no-plusplus
  //     i++;
  //   }
  //   return encodedString;
  //   // var s = '';
  //   // for (let i = 32; i <= 127; i++) s += String.fromCharCode(i);
  // };
  // const newEmail = encodeStringAlgorithm('msavastru@gmail.com');
  // console.log(`newEmail: ${newEmail}`);

  // const decodeStringAlgorithm = (string) => {
  //   if (!string || typeof string !== 'string') throw new Error('Wrong params');
  //   const stringLength = string.length;
  //   let i = 0;
  //   let decodedString = '';
  //   while (i < stringLength) {
  //     // const character = string[i];
  //     const asciiValue = string.codePointAt(i);
  //     // const dif = Math.ceil(asciiValue / getSumOfDigits(asciiValue));
  //     let newAsciiValue;
  //     if (asciiValue > x - dif) {
  //       newAsciiValue = asciiValue + dif;
  //     } else {
  //       newAsciiValue = asciiValue - dif;
  //     }
  //     const newCharacter = String.fromCharCode(newAsciiValue);
  //     decodedString += newCharacter;
  //     // eslint-disable-next-line no-plusplus
  //     i++;
  //   }
  //   return decodedString;
  //   // var s = '';
  //   // for (let i = 32; i <= 127; i++) s += String.fromCharCode(i);
  // };

  // // btoa/atob
  // const encodeBase64btoa = (string) => {
  //   if (!string || typeof string !== 'string') throw new Error('Wrong params');
  //   return window.btoa(string); // encode a string
  // };
  // const decodeBase64atob = (encodedData) => {
  //   if (!encodedData || typeof encodedData !== 'string') throw new Error('Wrong params');
  //   return window.atob(encodedData); // decode a string
  // };
  // const encodedData = encodeBase64btoa(newEmail);
  // console.log('encodeBase64btoa', encodedData);
  // const decodedData = decodeBase64atob(encodedData);
  // console.log('decodeBase64atob', decodedData);

  // Buffer.from
  encodeBase64buffer(value) {
    if (!value) throw new Error('Wrong params');
    const encodedValue = Buffer.from(JSON.stringify(value), 'binary').toString('base64'); // encode a string
    return encodedValue;
  }

  decodeBase64buffer(encodedValue) {
    if (!encodedValue) throw new Error('Wrong params');
    return JSON.parse(Buffer.from(encodedValue, 'base64').toString('binary')); // decode a string
  }
  // const encodedData2 = encodeBase64buffer(newEmail);
  // console.log('encodeBase64buffer', encodedData2);
  // const decodedData2 = decodeBase64buffer(encodedData);
  // console.log('decodeBase64buffer', decodedData2);

  // const myEmail1 = decodeStringAlgorithm(decodedData);
  // console.log(`myEmail1: ${myEmail1}`);
  // const myEmail2 = decodeStringAlgorithm(decodedData2);
  // console.log(`myEmail2: ${myEmail2}`);
}

export default new EncodeDecodeHelper();
