import moment from 'moment';

/**
 * getUnixTimestamp
 * @param {number} [date] - timestamp.
 * @returns {number}
 */
const getUnixTimestamp = (date) => moment(date).unix();

/**
 * getDiff
 * @param {number} a - timestamp.
 * @param {number} b - timestamp.
 * @returns {number}
 */
const getDiff = (a, b) => {
  if (!a && !b) throw new Error('Wrong params');
  return moment(a).diff(moment(b));
};

/**
 * add
 * @param {number} timestamp - timestamp
 * @param {number} time - time to add
 * @param {string} unit - milliseconds | seconds etc
 * @default seconds
 * @returns {date}
 */
const add = (time, unit, date) => {
  if (!time || !unit) throw new Error('Wrong params');
  return moment(date || Date.now()).add(time, unit);
};

/**
 * subtract
 * @param {date} date
 * @param {number} time - time to add
 * @param {string} unit - milliseconds | seconds etc
 * @default seconds
 * @returns {date}
 */
const subtract = (time, unit, date) => {
  if (!time || !unit) throw new Error('Wrong params');
  return moment(date || Date.now()).subtract(time, unit);
};

/**
 * isCacheExpired - check if used timestamp + ttl is before now
 * @param {number} timestamp - in seconds
 * @param {number} ttl - time to live: cache time in units
 * @param {string} unit - milliseconds | seconds etc
 * @default seconds
 * @returns {boolean}
 */
const isCacheExpired = (timestamp, ttl, unit = 'seconds') => {
  if (!timestamp || !ttl) return true;
  return moment.unix(timestamp).add(ttl, unit).isBefore();
};

export default { getUnixTimestamp, getDiff, isCacheExpired, add, subtract };
