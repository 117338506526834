/* eslint-disable react/jsx-curly-brace-presence */
import React, { memo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContainerContext } from '~/ServiceContainer';

import { PasscodeNumber, PasscodeRemove } from '~/styledComponents/atoms';

const codes = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'b', '0', 'x'];

const getValue = ({ symbol, key, onPress, remove, fade, setFade }) => {
  const onAnimationEnd = () => {
    setFade({ isFade: false, symbol: null });
  };
  switch (symbol) {
    case 'b':
      return (
        <PasscodeNumber key={key} backgroundColor="transparent">
          {''}
        </PasscodeNumber>
      );
    case 'x':
      return (
        <PasscodeRemove
          key={key}
          remove={remove(symbol)}
          isFade={fade.symbol === symbol}
          onAnimationEnd={onAnimationEnd}
        />
      );
    default:
      return (
        <PasscodeNumber
          key={key}
          onPress={onPress(symbol)}
          isFade={fade.symbol === symbol}
          onAnimationEnd={onAnimationEnd}
        >
          {symbol}
        </PasscodeNumber>
      );
  }
};

/**
 * PasscodeSymbols
 * @property {func} onPress
 * @property {func} remove
 * @property {Object} fade
 * @property {func} setFade
 */
const PasscodeSymbols = memo(({ onPress, remove, fade, setFade }) => {
  const { UUID } = useContext(ContainerContext);

  const renderItem = useCallback(
    (symbol, key) => getValue({ symbol, key, onPress, remove, fade, setFade }),
    [onPress, remove, fade, setFade]
  );
  return <Wrapper>{codes.map((symbol) => renderItem(symbol, UUID.v4()))}</Wrapper>;
});
PasscodeSymbols.propTypes = {
  onPress: PropTypes.func,
  remove: PropTypes.func,
  setFade: PropTypes.func,
  fade: PropTypes.shape({
    isFade: PropTypes.bool,
    symbol: PropTypes.string,
  }),
};
PasscodeSymbols.defaultProps = {
  onPress: () => {},
  remove: () => {},
  setFade: () => {},
  fade: PropTypes.shape({
    isFade: false,
    symbol: null,
  }),
};

export default PasscodeSymbols;

const Wrapper = styled.div`
  display: flex;
  width: 230px;
  flex-wrap: wrap;
  justify-content: space-around;
`;
