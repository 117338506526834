import React, { useContext } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { ContainerContext } from '~/ServiceContainer';

import usePrevious from '~/hooks/usePrevious';

import { Snackbar } from '~/styledComponents/molecules';

const useNotificationsWatcher = () => {
  const {
    config: { CACHE_TTL },
    notistack: { useSnackbar },
    utilsConstants: { toasters },
  } = useContext(ContainerContext);

  const storeNotifications = useSelector((store) => store.notifications, shallowEqual);
  const prevStoreNotifications = usePrevious(storeNotifications);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const createToaster = ({
    type = toasters.typeInfo.name,
    title,
    description,
    haveToClose,
    closeKey,
  }) => {
    if (!title) throw new Error('Wrong params');
    if (haveToClose) closeSnackbar(closeKey);
    return enqueueSnackbar(title, {
      persist: toasters[`type${type}`].persist,
      autoHideDuration: toasters[`type${type}`].autoHideDuration,
      anchorOrigin: toasters[`type${type}`].positioning,
      content: (key, message) => (
        <Snackbar type={type} id={key} title={message} description={description} />
      ),
    });
  };

  const displayToaster = () => {
    const { active, type, title, description, timestamp } = storeNotifications;

    if (!prevStoreNotifications?.timestamp) return;
    if (active && type && title) {
      if (title === prevStoreNotifications?.title) {
        if (timestamp - (prevStoreNotifications?.timestamp || 0) < CACHE_TTL.toastersDuplication) {
          return;
        }
      }
      createToaster({ type, title, description });
    }
  };
  return { createToaster, closeToaster: closeSnackbar, displayToaster };
};

export default useNotificationsWatcher;
