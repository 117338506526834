import styled from 'styled-components';
import {
  defaultBGColorGrey,
  defaultBGColorDarkGreen,
  flexCenterAlignments,
} from '../mainConstants';

/**
 * ScreenWrapper
 * @param {string} flexDirection - flex-direction value.
 * @default column
 * @param {string} justifyContent - justify-content value.
 * @default center
 * @param {string} alignItems - align-items value.
 * @default center
 * @param {string} bgColor - background-color value.
 * @default defaultBGColorGrey
 */
export const ScreenWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  background-color: ${({ bgColor }) => bgColor || defaultBGColorGrey};
`;

export const BlockWrapper = styled(ScreenWrapper)`
  flex: 1;
  height: unset;
`;

export const SectionWrapper = styled.div`
  flex: 1;
  display: flex;
`;

export const LoaderWrapper = styled.div`
  width: ${({ size }) => size || 100}px;
  height: ${({ size }) => size || 100}px;
  ${flexCenterAlignments}
  border-radius: ${({ size }) => (size || 100) / 2}px;
  background-color: ${defaultBGColorDarkGreen};
  z-index: 1;
`;
