import { createRoutine } from 'redux-saga-routines';
import { put } from 'redux-saga/effects';

export const changePage = createRoutine('CHANGE_PAGE');

export function* sagaOnChangePage({ payload }) {
  try {
    yield put(changePage.success(payload));
    localStorage.setItem('page', payload.page);
  } catch (e) {
    // handling error
  }
}
