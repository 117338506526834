import { takeLatest, all } from 'redux-saga/effects';

import {
  // with requests
  changeAuth,
  sagaOnChangeAuth,
  login,
  sagaLogin,
  signUp,
  sagaSignup,
  logout,
  sagaLogout,
  resetPassword,
  sagaResetPassword,
  forgotPassword,
  sagaForgotPassword,
  restorePassword,
  sagaRestorePassword,
  checkEmail,
  sagaCheckEmail,
  // without requests
  updateUserOnboarding,
  sagaUpdateUserOnboarding,
  updateUserProfile,
  sagaUpdateUserProfile,
} from './user.action';

export default function* userWatcher() {
  // with requests
  yield all([takeLatest(login.TRIGGER, sagaLogin)]);
  yield all([takeLatest(signUp.TRIGGER, sagaSignup)]);
  yield all([takeLatest(logout.TRIGGER, sagaLogout)]);
  yield all([takeLatest(resetPassword.TRIGGER, sagaResetPassword)]);
  yield all([takeLatest(forgotPassword.TRIGGER, sagaForgotPassword)]);
  yield all([takeLatest(restorePassword.TRIGGER, sagaRestorePassword)]);
  yield all([takeLatest(checkEmail.TRIGGER, sagaCheckEmail)]);
  // without requests
  yield all([takeLatest(changeAuth.TRIGGER, sagaOnChangeAuth)]);
  yield all([takeLatest(updateUserOnboarding.TRIGGER, sagaUpdateUserOnboarding)]);
  yield all([takeLatest(updateUserProfile.TRIGGER, sagaUpdateUserProfile)]);
}
