import * as uuid from 'uuid';
import Bugsnag from '@bugsnag/js';
import moment from 'moment';
import lodash from 'lodash';
import * as notistack from 'notistack';
import * as reactHookForm from 'react-hook-form';
import * as hookFormResolvers from '@hookform/resolvers/joi';
import Joi from 'joi';
import MoonLoader from 'react-spinners/MoonLoader';
import FadeLoader from 'react-spinners/FadeLoader';
import ClockLoader from 'react-spinners/ClockLoader';

// Icons
import * as feather from '@styled-icons/feather';
import * as evaiconsOutline from '@styled-icons/evaicons-outline';
import * as ioniconsOutline from '@styled-icons/ionicons-outline';

// Config
import config from '~/configs/default';

// Routes
import routes from '~/navigation/routes';

// Utils
import UtilsConstants from '~/utils/constants';

// Helpers
import helpers from '~/utils/helpers';

// Joi Schemas
import JoiSchemas from '~/utils/joiSchemas';

// Services
import AxiosService from './services/AxiosService';

import PackageJSON from '../../package.json';

Bugsnag.start({
  apiKey: 'b00a412c88c718bc8c11a1b5a97a646d',
  appVersion: PackageJSON.version,
  metadata: {
    company: {
      uuid: uuid.v4(),
      name: 'Visiter',
      country: 'ukr',
    },
  },
});

const containerValues = {
  theme: 'Day',
  config,
  routes,
  utilsConstants: UtilsConstants,
  helpers,
  JoiSchemas,
  // libraries
  UUID: uuid,
  Joi,
  Bugsnag,
  moment,
  lodash,
  notistack,
  reactHookForm,
  hookFormResolvers,
  spinners: {
    MoonLoader,
    FadeLoader,
    ClockLoader,
  },
  icons: { feather, evaiconsOutline, ioniconsOutline },
};

// add services to Container
containerValues.AxiosService = new AxiosService(containerValues);

export default containerValues;
