/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const ErrorPage = () => (
  <div>
    <h2>Oooop's :)</h2>
    <h3>
      Please, don't panic. <p>We are solving the technical problems.</p>
      <p>Come back little bit later :)</p>
    </h3>
  </div>
);

export default ErrorPage;
