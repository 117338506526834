import Joi from 'joi';

import { uuid, firstName, lastName, email, password, recoveryCode } from './common';

const registrationSchema = Joi.object({
  id: uuid,
  email,
  firstName,
  lastName,
  password,
});

const loginSchema = Joi.object({
  email,
  password,
});

const recoverySchema = Joi.object({
  email,
});

const recoveryConfirmationSchema = Joi.object({
  recoveryCode,
});

const updatePasswordSchema = Joi.object({
  password,
});

export default {
  registrationSchema,
  loginSchema,
  recoverySchema,
  recoveryConfirmationSchema,
  updatePasswordSchema,
};
