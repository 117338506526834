import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import utilsConstants from '~/utils/constants';

import useNotificationsWatcher from './useNotificationsWatcher';

const {
  toasters: { typeInfo },
  translator,
} = utilsConstants;

const useWindowListenersOnlineOffline = () => {
  const { createToaster } = useNotificationsWatcher();

  const { shortName: lngShortName } = useSelector((store) => store.common.language, shallowEqual);

  const [isOffline, setOffline] = useState(false);

  useEffect(() => {
    let key;
    window.addEventListener('offline', () => {
      setOffline(true);
      const { title, description } = translator[lngShortName].errorMessages.internetConnection;
      key = createToaster({ type: typeInfo.name, title, description });
    });
    window.addEventListener('online', async () => {
      setOffline(false);
      // closeToaster(); // to remove all previous notifications firstly
      createToaster({ type: typeInfo.name, title: 'Online', haveToClose: true, closeKey: key });
    });
    return () => {
      window.removeEventListener('offline', () => console.log('listener offline is removed'));
      window.removeEventListener('online', () => console.log('listener online is removed'));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { isOffline };
};

export default useWindowListenersOnlineOffline;
