import { createRoutine } from 'redux-saga-routines';

import { put } from 'redux-saga/effects';

// JFI: if we evoke setLoading.trigger() in saga it will firstly evoke trigger and then success in sagaOnSetLoading
export const setLoading = createRoutine('SET_LOADING');

export const trueLoading = {
  isLoading: true,
};
export const falseLoading = {
  isLoading: false,
};

export function* sagaOnSetLoading({ payload }) {
  try {
    const { isLoading, type } = payload || {};
    yield put(
      setLoading.success({
        isLoading,
        type,
      })
    );
  } catch (e) {
    // handling error
  }
}
