import { handleActions } from 'redux-actions';

import { ContainerValues } from '~/ServiceContainer';

import { setLanguage } from '~/redux/actions/languages/languages.action';
import { changePage } from '~/redux/actions/common/common.action';

const {
  utilsConstants: { defaultLanguage },
} = ContainerValues;

export const initialState = {
  user: {
    auth: false,
    profile: {},
  },
  language: defaultLanguage,
  isLoading: false,
  page: 'home',
  error: { status: false, message: '' },
};

export default handleActions(
  {
    [setLanguage.TRIGGER](state) {
      return { ...state, isLoading: true };
    },
    [setLanguage.SUCCESS](state, action) {
      return { ...state, isLoading: false, language: action.payload };
    },
    [setLanguage.FAILURE](state, action) {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    },
    [changePage.SUCCESS](state, action) {
      return { ...state, page: action.payload.page };
    },
  },
  initialState
);

// For using >1 data object use like this:
// import { combineReducers } from 'redux';
// export default combineReducers({
//   data,
// });
