import styled from 'styled-components';
import {
  defaultBorderColorDarkBlue,
  defaultFontColorDarkBlue,
  defaultBtColorTurquoise,
  flexCenterAlignments,
  defaultBtColorDarkGreen,
  defaultBGColorGrey,
  defaultModalsBorderRadius,
  defaultFontColorTurquoise,
} from './mainConstants';

// /**
//  * ButtonDiv
//  * @param {string} padding - padding value.
//  * @default defaultBtPadding
//  * @param {string} borderRadius - border-radius value.
//  * @default defaultBtBorderRadius
//  * @param {string} fontWeight - font-weight value.
//  * @default 700
//  * @param {string} bgColor - background-color value.
//  * @default defaultBtColorTurquoise
//  * @param {string} textColor - align-items value.
//  * @default defaultFontColorDarkBlue
//  */
export const ModalWrapper = styled.div(() => ({
  position: 'fixed',
  top: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexCenterAlignments,
  'z-index': '5',
}));

export const ModalBackground = styled.div(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  'background-color': defaultBtColorDarkGreen,
  opacity: 0.7,
}));

export const ContentWrapper = styled.div(({ width, height }) => ({
  width: width || '190px',
  height: height || '190px',
  display: 'flex',
  'flex-direction': 'column',
  flexCenterAlignments,
  padding: '20px',
  'background-color': defaultBGColorGrey,
  'border-radius': defaultModalsBorderRadius,
  border: `0.2px solid ${defaultBorderColorDarkBlue}`,
  'z-index': '5',
}));

export const Title = styled.h6(() => ({
  'font-size': '22px',
  'font-weight': '900',
  color: defaultFontColorTurquoise,
  'text-align': 'center',
  margin: '0 0 20px',
}));

export const LanguagesWrapper = styled.div(() => ({
  width: '130px',
  display: 'flex',
  'flex-direction': 'column',
  flexCenterAlignments,
}));

export const Language = styled.span(({ isActive }) => ({
  cursor: 'default',
  width: '130px',
  height: '20px',
  margin: '3px 0',
  'border-radius': '3px',
  'text-align': 'center',
  'background-color': isActive ? defaultBtColorTurquoise : 'transparent',
  color: isActive ? defaultFontColorDarkBlue : defaultFontColorTurquoise,
}));
