export default {
  // --- PUBLIC ---
  onboarding: '/',
  // Log in
  loginEmail: '/login-email',
  loginPasscode: '/login-passcode',
  // Sign Up
  signUpInfo: '/signup',
  signUpPasscode: '/signup-passcode',
  activationEmail: '/activation-email',
  newEmail: '/new-email',
  // Forgot Passcode
  forgotPasscodeEmail: '/forgot-passcode-email', // enter email
  forgotPasscodeConfirm: '/forgot-passcode-confirm', // enter code TODO: think about auto handling link in Navigator
  // Terms & Conditions
  termsConditions: '/terms-conditions',
  terms: '/terms-conditions/terms',
  conditions: '/terms-conditions/conditions',
  // --- PROTECTED ---
  // Tabs
  home: '/home',
  mySpots: '/my-spots',
  allSpots: '/all-spots',
  // Profile
  profile: '/profile',
  profileDetails: 'profile/details',
  security: '/security',
};
