import styled from 'styled-components';

import {
  defaultFontSize,
  fontWeightRegular,
  defaultFontColorWhite,
  defaultFontColorTurquoise,
  defaultMargin,
  padding0,
} from '../mainConstants';

/**
 * ButtonDiv
 * @param {number} size - font-size.
 * @default defaultFontSize
 * @param {number} weight - font-weight.
 * @default fontWeightRegular
 * @param {string} color - color.
 * @default defaultFontColorDarkBlue
 * @param {string} padding - padding.
 * @default 0px
 */
export const Text = styled.div(
  ({
    size = defaultFontSize,
    weight = fontWeightRegular,
    color = defaultFontColorWhite,
    padding = padding0,
  }) => ({
    'font-size': `${size}px`,
    'font-weight': `'${weight}'`,
    'font-family': 'Helvetica',
    color,
    padding,
  })
);

export const Title = styled.h1`
  color: ${({ color }) => color || defaultFontColorTurquoise};
  margin: ${({ margin }) => margin || defaultMargin};
`;
export const TitleThin = styled.h1`
  color: ${({ color }) => color || defaultFontColorTurquoise};
  margin: ${({ margin }) => margin || defaultMargin};
  font-weight: 500;
`;
