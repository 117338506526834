import React, { createContext } from 'react';
import defaultContainerValues from './default';
import prodContainerValues from './prod';
import stagingContainerValues from './staging';
import localContainerValues from './local';

// eslint-disable-next-line import/no-mutable-exports
let containerValues;

switch (process.env.REACT_APP_ENVIRONMENT) {
  case 'production':
    containerValues = {
      ...defaultContainerValues,
      ...prodContainerValues,
    };
    break;
  case 'staging':
    containerValues = {
      ...defaultContainerValues,
      ...stagingContainerValues,
    };
    break;
  case 'local':
    containerValues = {
      ...defaultContainerValues,
      ...localContainerValues,
    };
    break;
  default:
    containerValues = defaultContainerValues;
    break;
}

const ContainerContext = createContext(containerValues);
ContainerContext.displayName = 'ContainerContext';
const { Provider, Consumer } = ContainerContext;

const ContainerContextProvider = ({ children }) => (
  <Provider value={containerValues}>{children}</Provider>
);
export {
  ContainerContextProvider,
  Consumer as ContainerContextConsumer,
  ContainerContext,
  containerValues as ContainerValues,
};
