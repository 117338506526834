import styled from 'styled-components';

import {
  defaultFontSize,
  fontWeightRegular,
  fontWeightSemiBold,
  defaultBorderColorRed,
  defaultBtBorderRadius,
  defaultFontColorDarkBlue,
  defaultMargin,
  defaultBtPadding,
  displayBlock,
  colorTransparent,
} from '../mainConstants';

/**
 * ButtonDiv
 * @param {number} size - font-size.
 * @default defaultFontSize
 * @param {number} weight - font-weight.
 * @default fontWeightRegular
 * @param {string} color - color.
 * @default defaultFontColorDarkBlue
 * @param {string} padding - padding.
 * @default 0px
 */
export const Input = styled.input(
  ({
    size = defaultFontSize,
    weight = fontWeightSemiBold,
    color = defaultFontColorDarkBlue,
    padding = defaultBtPadding,
    isError,
    display = displayBlock,
  }) => ({
    display,
    'min-width': '260px',
    'max-width': '320px',
    height: '20px',
    'font-size': `${size}px`,
    'font-weight': weight,
    color,
    padding,
    border: `2px solid ${isError ? defaultBorderColorRed : colorTransparent}`,
    borderRadius: defaultBtBorderRadius,
    'margin-bottom': defaultMargin,
    /* Edge */
    '::-webkit-input-placeholder': {
      'font-weight': fontWeightRegular,
    },
    /* Internet Explorer 10-11 */
    ':-ms-input-placeholder': {
      'font-weight': fontWeightRegular,
    },
    '::placeholder': {
      'font-weight': fontWeightRegular,
    },
  })
);

export const fake = 'fake';
