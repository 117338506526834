/* eslint-disable react/forbid-prop-types */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Checkbox, Icons, Text } from '~/styledComponents/atoms';
import { SectionWrapper } from '~/styledComponents/wrappers/Wrappers';
import { defaultFontColorTurquoise } from '../mainConstants';

const { Checkmark } = Icons;

/**
 * Checkbox with Text
 * @property {Boolean} value
 * @property {String} description
 * @property {func} onClick
 * @property {func} onBlur
 * @property {Boolean} isError
 */
const CheckboxWithText = forwardRef(
  ({ value, description, onClick, onBlur, isError, ...rest }, ref) => (
    <SectionWrapper>
      <Checkbox
        onBlur={onBlur} // notify when input is touched
        onClick={onClick}
        checked={value}
        inputRef={ref}
        isError={isError}
        {...rest}
      >
        {value && <Checkmark size={15} />}
      </Checkbox>
      <Text color={defaultFontColorTurquoise} padding="0px 10px" onClick={onClick}>
        {description}
      </Text>
    </SectionWrapper>
  )
);
CheckboxWithText.propTypes = {
  value: PropTypes.bool,
  description: PropTypes.string,
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  isError: PropTypes.bool,
};

CheckboxWithText.defaultProps = {
  value: false,
  description: 'Remember me',
  onClick: () => {},
  onBlur: () => {},
  isError: false,
};

export default CheckboxWithText;
