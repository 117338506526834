import { ContainerValues } from '~/ServiceContainer';

const {
  AxiosService: { fetch },
} = ContainerValues;

export const contactService = (body = { test: 'test' }) => {
  console.warn('contactService start');
  return fetch('post', 'contact', body);
};

export const redditBestService = (route) => {
  console.warn('redditBestService start');
  return fetch('get', route);
};
