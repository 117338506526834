import styled from 'styled-components';

import { ContainerValues } from '~/ServiceContainer';

import { defaultIconColorTurquoise } from '../mainConstants';

const {
  icons: {
    evaiconsOutline: { CheckmarkOutline },
  },
} = ContainerValues;

// /**
//  * ButtonDiv
//  * @param {string} padding - padding value.
//  * @default defaultBtPadding
//  * @param {string} margin - margin value.
//  * @default 0px
//  * @param {string} borderRadius - border-radius value.
//  * @default defaultBtBorderRadius
//  * @param {string} fontWeight - font-weight value.
//  * @default 700
//  * @param {string} bgColor - background-color value.
//  * @default defaultBtColorTurquoise
//  * @param {string} textColor - align-items value.
//  * @default defaultFontColorDarkBlue
//  */
export const Checkmark = styled(CheckmarkOutline)`
  color: ${({ color }) => color || defaultIconColorTurquoise};
`;

// TODO: remove when new export appear
export const Checkmark2 = styled(CheckmarkOutline)`
  border-radius: ${({ size }) => size / 2}px;
  color: ${({ color }) => color || defaultIconColorTurquoise};
`;
