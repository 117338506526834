import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { ContainerContext } from '~/ServiceContainer';

/**
 * useCache - hook for using cached data
 * @returns {object} object with cachedDispatch
 */
const useCache = () => {
  const dispatch = useDispatch();
  const {
    helpers: { DateHelper },
    config: { CACHE_TTL },
    utilsConstants: { cacheKeys },
  } = useContext(ContainerContext);

  /**
   * cachedDispatch - use cache or retrieve data
   * @param {function} action - redux action
   * @param {any} date - date, when last time data was retrieved
   * @param {String} cacheKey - ttl key from config
   * @returns dispatch result or {} cache is not expired
   */
  const cachedDispatch = (action, date, cacheKey = cacheKeys.commonRequests) => {
    if (date) {
      if (DateHelper.isCacheExpired(date, CACHE_TTL[cacheKey])) {
        return dispatch(action);
      }
      return {};
    }
    return dispatch(action);
  };

  return { cachedDispatch };
};

export default useCache;
