import { createRoutine } from 'redux-saga-routines';
import { put, call, select } from 'redux-saga/effects';

import { ContainerValues } from '~/ServiceContainer';

import { setLoading, trueLoading, falseLoading } from '~/redux/actions/loading/loading.action';
import { setNotification } from '~/redux/actions/notifications/notifications.action';
import { getUserProfile } from '~/redux/actions/user/user.selectors';

import {
  loginRequest,
  signupRequest,
  logoutRequest,
  resetPasswordRequest,
  forgotPasswordRequest,
  restorePasswordRequest,
  checkEmailRequest,
} from '~/api/requests/user';

// with requests
export const login = createRoutine('LOGIN');
export const signUp = createRoutine('SIGNUP');
export const logout = createRoutine('LOGOUT');
export const resetPassword = createRoutine('RESET_PASSWORD');
export const forgotPassword = createRoutine('FORGOT_PASSWORD');
export const restorePassword = createRoutine('RESTORE');
export const checkEmail = createRoutine('CHECK_EMAIL');
// without requests
export const changeAuth = createRoutine('CHANGE_AUTH');
export const updateUserOnboarding = createRoutine('UPDATE_USER_ONBOARDING');
export const updateUserProfile = createRoutine('UPDATE_USER_PROFILE');

const {
  utilsConstants: {
    https: { xTkn },
    defaultErrorMessage,
    toasters: { typeSuccess, typeError },
  },
  AxiosService,
} = ContainerValues;

// eslint-disable-next-line no-promise-executor-return
const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

export const getSuccessNotification = (title, description) => ({
  active: true,
  type: typeSuccess.name,
  title,
  description,
});
export const getFailureNotification = (message) => ({
  active: true,
  type: typeError.name,
  title: message || defaultErrorMessage,
});

export function* sagaOnChangeAuth({ payload }) {
  try {
    yield call(delay, 2000); // fake delay
    const { auth } = payload;
    yield !auth && AxiosService.removeHeader(xTkn);
    yield put(changeAuth.success(payload));
  } catch (e) {
    // code for handling errors
  }
}

export function* sagaLogin({ payload }) {
  try {
    yield put(setLoading.success(trueLoading));
    // yield call(delay, 2000); // fake delay
    const response = yield call(loginRequest, { body: payload });
    yield put(setLoading.success(falseLoading));
    yield put(login.success(response.data));
  } catch (e) {
    yield put(login.failure(payload));
    yield put(setLoading.failure());
    const notificationData = getFailureNotification(e?.message);
    yield put(setNotification.failure(notificationData));
  }
}

export function* sagaSignup({ payload }) {
  try {
    yield put(setLoading.success({ isLoading: true }));
    // yield call(delay, 2000); // fake delay
    const response = yield call(signupRequest, { body: payload });
    yield put(signUp.success(response.data));
    yield put(setLoading.success({ isLoading: false }));
  } catch (e) {
    yield put(signUp.failure(payload));
    yield put(setLoading.failure());
    const notificationData = getFailureNotification(e?.message);
    yield put(setNotification.failure(notificationData));
  }
}

export function* sagaLogout() {
  const userProfile = yield select(getUserProfile);
  try {
    yield call(delay, 2000); // fake delay
    const response = yield call(logoutRequest, {
      userId: userProfile.id,
    });
    yield AxiosService.removeHeader(xTkn);
    yield put(logout.success(response.data));
  } catch (e) {
    const notificationData = getFailureNotification(e?.message);
    yield put(setNotification.failure(notificationData));
  }
}

export function* sagaResetPassword({ payload }) {
  try {
    yield call(delay, 2000); // fake delay
    const response = yield call(resetPasswordRequest, { body: payload });
    yield put(resetPassword.success(response.data));
    const notificationData = getSuccessNotification('Passcode is reset successfully');
    yield put(setNotification.success(notificationData));
  } catch (e) {
    console.error('error', e);
    // TODO: check if need and remove if don't
    yield put(resetPassword.failure(payload));
    const notificationData = getFailureNotification(e?.message);
    yield put(setNotification.failure(notificationData));
  }
}

export function* sagaForgotPassword({ payload }) {
  try {
    yield call(delay, 2000); // fake delay
    const response = yield call(forgotPasswordRequest, { body: payload });
    yield put(forgotPassword.success(response.data));
    const notificationData = getSuccessNotification(response.data.message);
    yield put(setNotification.success(notificationData));
  } catch (e) {
    // TODO: check if need and remove if don't
    yield put(forgotPassword.failure(payload));
    const notificationData = getFailureNotification(e?.message);
    yield put(setNotification.failure(notificationData));
  }
}

export function* sagaRestorePassword({ payload }) {
  try {
    yield call(delay, 2000); // fake delay
    const response = yield call(restorePasswordRequest, { body: payload });
    yield put(restorePassword.success(response.data));
    const notificationData = getSuccessNotification(response.data.message);
    yield put(setNotification.success(notificationData));
  } catch (e) {
    console.error('error', e);
    // TODO: check if need and remove if don't
    yield put(restorePassword.failure(payload));
    const notificationData = getFailureNotification(e?.message);
    yield put(setNotification.failure(notificationData));
  }
}

export function* sagaCheckEmail({ payload }) {
  try {
    yield call(delay, 2000); // fake delay
    const response = yield call(checkEmailRequest, { body: payload });
    yield put(checkEmail.success(response.data));
  } catch (e) {
    console.error('error', e);
    // TODO: check if need and remove if don't
    yield put(checkEmail.failure(payload));
    const notificationData = getFailureNotification(e?.message);
    yield put(setNotification.failure(notificationData));
  }
}

export function* sagaUpdateUserOnboarding({ payload }) {
  try {
    yield put(updateUserOnboarding.success(payload));
  } catch (e) {
    const notificationData = getFailureNotification(e?.message);
    yield put(setNotification.failure(notificationData));
  }
}

export function* sagaUpdateUserProfile({ payload }) {
  try {
    yield put(updateUserProfile.success(payload));
  } catch (e) {
    const notificationData = getFailureNotification(e?.message);
    yield put(setNotification.failure(notificationData));
  }
}
