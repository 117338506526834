import React, { useContext } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { ContainerContext } from '~/ServiceContainer';
import useNavigation from '~/hooks/useNavigation';

const RequireAuth = ({ children }) => {
  const { Navigate, location } = useNavigation();

  const {
    routes: { loginEmail, loginPasscode },
  } = useContext(ContainerContext);

  const {
    auth,
    profile: { email, rememberMe },
  } = useSelector((store) => store.user, shallowEqual);

  if (!auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    const to = email && rememberMe ? loginPasscode : loginEmail;
    return <Navigate to={to} state={{ from: location }} replace />;
  }
  return children;
};

export default RequireAuth;
