import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContainerValues } from '~/ServiceContainer';

import { SlowOnset } from '~/styledComponents/effects/SlowOnset';

import {
  defaultBtBorderRadius,
  defaultBtColorTurquoise,
  defaultBtPadding,
  flexCenterAlignments,
  defaultFontColorDarkBlue,
  defaultFontSize,
  fontWeightSemiBold700,
  margin0,
} from '../mainConstants';

const {
  icons: {
    feather: { Delete },
  },
} = ContainerValues;

/**
 * ButtonDiv
 * @param {string} padding - padding value.
 * @default defaultBtPadding
 * @param {string} margin - margin value.
 * @default 0px
 * @param {string} borderRadius - border-radius value.
 * @default defaultBtBorderRadius
 * @param {string} fontWeight - font-weight value.
 * @default 700
 * @param {string} bgColor - background-color value.
 * @default defaultBtColorTurquoise
 * @param {string} textColor - align-items value.
 * @default defaultFontColorDarkBlue
 */
export const ButtonDiv = styled.div(
  ({ padding, borderRadius, fontWeight, bgColor, textColor, margin }) => ({
    cursor: 'pointer',
    width: '140px',
    height: '25px',
    display: 'flex',
    flexCenterAlignments,
    padding: padding || defaultBtPadding,
    margin: margin || margin0,
    'border-radius': borderRadius || defaultBtBorderRadius,
    'background-color': bgColor || defaultBtColorTurquoise,
    'font-weight': fontWeight || fontWeightSemiBold700,
    color: textColor || defaultFontColorDarkBlue,
  })
);

export const ButtonLink = styled.a(
  ({ padding, borderRadius, fontWeight, bgColor, textColor, margin }) => ({
    width: '140px',
    height: '25px',
    display: 'flex',
    flexCenterAlignments,
    padding: padding || defaultBtPadding,
    margin: margin || margin0,
    'border-radius': borderRadius || defaultBtBorderRadius,
    'background-color': bgColor || defaultBtColorTurquoise,
    'font-weight': fontWeight || fontWeightSemiBold700,
    color: textColor || defaultFontColorDarkBlue,
  })
);

export const Button = styled.button(
  ({
    size = defaultFontSize,
    padding,
    borderRadius,
    fontWeight,
    bgColor,
    textColor,
    margin,
    border,
    alignSelf,
  }) => ({
    width: '140px',
    height: '40px',
    display: 'flex',
    flexCenterAlignments,
    padding: padding || defaultBtPadding,
    border: border || 'none',
    margin: margin || margin0,
    'border-radius': borderRadius || defaultBtBorderRadius,
    'background-color': bgColor || defaultBtColorTurquoise,
    'font-size': `${size}px`,
    'font-weight': fontWeight || fontWeightSemiBold700,
    color: textColor || defaultFontColorDarkBlue,
    alignSelf,
  })
);

const PasscodeNumberBtWrapper = styled.div`
  cursor: pointer;
  display: flex;
  ${flexCenterAlignments};
  padding: 17px;
`;

export const PasscodeNumberBt = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: flex;
  ${flexCenterAlignments};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ textColor }) => textColor};
`;

export const PasscodeNumber = memo(
  ({
    onPress,
    size,
    fontSize,
    fontWeight,
    backgroundColor,
    textColor,
    borderRadius,
    children,
    isFade,
    onAnimationEnd,
  }) => (
    <PasscodeNumberBtWrapper onClick={onPress} onAnimationEnd={onAnimationEnd}>
      <SlowOnset noBlink={!isFade} timing="0.2s">
        <PasscodeNumberBt
          isFade={isFade}
          size={size}
          fontSize={fontSize}
          backgroundColor={backgroundColor}
          textColo={textColor}
          fontWeight={fontWeight}
          borderRadius={borderRadius}
        >
          {children}
        </PasscodeNumberBt>
      </SlowOnset>
    </PasscodeNumberBtWrapper>
  )
);
PasscodeNumber.propTypes = {
  size: PropTypes.number,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  borderRadius: PropTypes.string,
  onPress: PropTypes.func,
  onAnimationEnd: PropTypes.func,
  isFade: PropTypes.bool,
};
PasscodeNumber.defaultProps = {
  size: 30,
  fontSize: 20,
  fontWeight: 700,
  backgroundColor: defaultBtColorTurquoise,
  textColor: defaultFontColorDarkBlue,
  borderRadius: defaultBtBorderRadius,
  onPress: () => {},
  onAnimationEnd: () => {},
  isFade: false,
};

const PasscodeRemoveBtWrapper = styled.div`
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  ${flexCenterAlignments};
  padding: 14.5px;
`;

export const PasscodeRemoveBt = styled(Delete)`
  // color: ${({ color }) => color};
`;

export const PasscodeRemove = ({ remove, size, color, isFade, onAnimationEnd }) => (
  <PasscodeRemoveBtWrapper onClick={remove} onAnimationEnd={onAnimationEnd}>
    <SlowOnset noBlink={!isFade} timing="0.2s">
      <PasscodeRemoveBt size={size} color={color} />
    </SlowOnset>
  </PasscodeRemoveBtWrapper>
);
PasscodeRemove.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  remove: PropTypes.func,
  onAnimationEnd: PropTypes.func,
  isFade: PropTypes.bool,
};
PasscodeRemove.defaultProps = {
  size: 35,
  color: defaultBtColorTurquoise,
  remove: () => {},
  onAnimationEnd: () => {},
  isFade: false,
};

const PasscodeDotBt = styled.div`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border: 1px solid ${({ color }) => color};
  border-radius: ${({ size }) => size}px;
  background-color: ${({ isFill, color }) => (isFill ? color : 'transparent')};
`;

export const PasscodeDot = ({ isFill, size, color, isFade, onAnimationEnd }) => (
  <SlowOnset noBlink={!isFade} timing="0.3s">
    <PasscodeDotBt size={size} color={color} isFill={isFill} onAnimationEnd={onAnimationEnd} />
  </SlowOnset>
);
PasscodeDot.propTypes = {
  isFill: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  onAnimationEnd: PropTypes.func,
  isFade: PropTypes.bool,
};
PasscodeDot.defaultProps = {
  isFill: false,
  size: 12,
  color: defaultBtColorTurquoise,
  onAnimationEnd: () => {},
  isFade: false,
};
