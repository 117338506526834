import { useContext } from 'react';
import * as reactRouterDom from 'react-router-dom';

import { ContainerContext } from '~/ServiceContainer';

/**
 * useNavigation - use Navigation to navigate user by App
 * @returns {Object} 'react-router-dom' and custom methods
 */
const useNavigation = () => {
  const { useNavigate, useLocation } = reactRouterDom;
  const navigate = useNavigate();
  const location = useLocation();

  const {
    routes: { signUpPasscode, loginEmail, loginPasscode },
  } = useContext(ContainerContext);

  const navSignUpPasscode = () => navigate(signUpPasscode);
  const navLoginEmail = () => navigate(loginEmail);
  const navLoginPasscode = () => navigate(loginPasscode);

  const navBack = () => navigate(-1);

  return {
    ...reactRouterDom,
    navigate,
    location,
    navBack,
    navSignUpPasscode,
    navLoginEmail,
    navLoginPasscode,
  };
};

export default useNavigation;
