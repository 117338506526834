export default (store) => (next) => async (action) => {
  const { type, payload } = await action;
  console.warn('GLOBAL ERROR HANDLER store, type, payload', store, type, payload);
  // const metadata = {
  //   ...payload,
  // };

  // bugsnag && bugsnag.leaveBreadcrumb(`${type.slice(0, 30)}`, metadata);
  // if (
  //   type.includes('FAILURE') &&
  //   !exceptions.includes(type) &&
  //   payload?.response?.status !== 401
  // ) {
  //   const message = errorMessage(payload);
  //   const id = generateId(6);
  //   const detailedError = {
  //     id: id,
  //     type: type,
  //     message: message,
  //     date: moment.now(),
  //     url: payload?.responseMessage?.config?.url,
  //     response: payload,
  //   };
  //   bugsnag.notify(new Error(JSON.stringify(detailedError)));
  //   handleError(message, id);
  // }
  next(action);
};
