import { all, fork } from 'redux-saga/effects';

import languagesWatcher from './actions/languages/languages.saga';
import notificationsWatcher from './actions/notifications/notifications.saga';
import commonWatcher from './actions/common/common.saga';
import globalLoadingWatcher from './actions/loading/loading.saga';
import userWatcher from './actions/user/user.saga';

export default function* rootSaga() {
  yield all([
    fork(languagesWatcher),
    fork(notificationsWatcher),
    fork(commonWatcher),
    fork(globalLoadingWatcher),
    fork(userWatcher),
  ]);
}
