import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import { ContainerContext } from '~/ServiceContainer';

import { setLanguage } from '~/redux/actions/languages/languages.action';

import useCache from '~/hooks/useCache';

import {
  ModalWrapper,
  ModalBackground,
  ContentWrapper,
  Title,
  LanguagesWrapper,
  Language,
} from '~/styledComponents/Modals';

const LanguagesModal = memo(({ closeModal }) => {
  const { cachedDispatch } = useCache();
  const {
    utilsConstants: { languages },
  } = useContext(ContainerContext);

  const activeLng = useSelector((store) => store.common.language, shallowEqual);

  const onChangeLanguage = (lng) => () => cachedDispatch(setLanguage(lng));
  return (
    <ModalWrapper onClick={closeModal}>
      <ModalBackground />
      <ContentWrapper>
        <Title>{'Choose your\nlanguage:'}</Title>
        <LanguagesWrapper>
          {languages.map((lng) => (
            <Language
              key={`${lng.shortName}_${Date.now()}`}
              onClick={onChangeLanguage(lng)}
              isActive={activeLng.shortName === lng.shortName}
            >
              {lng.name}
            </Language>
          ))}
        </LanguagesWrapper>
      </ContentWrapper>
    </ModalWrapper>
  );
});

LanguagesModal.propTypes = {
  closeModal: PropTypes.func,
};

LanguagesModal.defaultProps = {
  closeModal: () => {},
};

export default LanguagesModal;
