import DefaultBugsnag from '@bugsnag/js';

// Config
import config from '~/configs/local';
// Mocks
import BugsnagMock from './localMocks/bugsnag';

const containerValues = {
  testStagingService: 'testStagingService',
  Bugsnag: config.TRACKERS.bugsnag ? DefaultBugsnag : BugsnagMock,
};

// add services to Container example:
// containerValues.TestService = new TestService(containerValues);

export default containerValues;
