import { css } from 'styled-components';

// colors
const turquoise = '#2eccb4';
const turquoise2 = '#2DCCB4';
const darkBlue = '#132846';
const lightBlue = '#91b5e9';
const grey = '#3B495E';
const lightGrey = '#7b8596';
const white = '#ffffff';
const lightYellow = '#fcf3b0';
const yellow = '#f6db6d';
const darkYellow = '#e38f00';
const orange = '#EA8924';
const lightPink = '#ff9e9e';
const pink = '#e58d8d';
const lightRed = '#ea3f2480';
const red = '#c3351e';
const lightGreen = '#a2ffb1';
const green = '#229C89';
const darkGreen = '#195a66';
export const colorTransparent = 'transparent';

// default width, height
export const maxDesktopScreenWidth = '1300px';
export const maxTabletScreenWidth = '768px';
export const maxMobileScreenWidth = '320px';

// default margin, padding
export const margin0 = '0px';
export const padding0 = '0px';
export const defaultScreenPadding = '30px';
export const defaultMargin = '15px';
export const defaultBtPadding = '10px';

// default display
export const displayBlock = 'block';

// default background-colors
export const defaultBGColorGrey = grey;
export const defaultBGColorWhite = white;
export const defaultBGColorDarkBlue = darkBlue;
export const defaultBGColorLightBlue = lightBlue;
export const defaultBGColorLightYellow = lightYellow;
export const defaultBGColorYellow = yellow;
export const defaultBGColorDarkYellow = darkYellow;
export const defaultBGColorLightGreen = lightGreen;
export const defaultBGColorGreen = green;
export const defaultBGColorDarkGreen = darkGreen;
export const defaultBGColorLightPink = lightPink;
export const defaultBGColorPink = pink;
export const defaultBGColorLightRed = lightRed;
export const defaultBGColorRed = red;

// default icons-colors
export const defaultIconColorLightBlue = lightBlue;
export const defaultIconColorDarkBlue = darkBlue;
export const defaultIconColorYellow = yellow;
export const defaultIconColorDarkYellow = darkYellow;
export const defaultIconColorLightGreen = lightGreen;
export const defaultIconColorDarkGreen = darkGreen;
export const defaultIconColorLightPink = lightPink;
export const defaultIconColorLightRed = lightRed;
export const defaultIconColorRed = red;

// default font-sizes
export const defaultFontSize = 14;
export const defaultFontSizeBigTitle = 30;
export const defaultFontSizeMiddleTitle = 24;
export const defaultFontSizeTitle = 18;

// default font-weight
export const fontWeightRegular = '400';
export const fontWeightSemiBold = '600';
export const fontWeightSemiBold700 = '700';
export const fontWeightBold = '900';

// default font-colors
export const defaultFontColorWhite = white;
export const defaultFontColorGrey = grey;
export const defaultFontColorLightGrey = lightGrey;
export const defaultFontColorDarkBlue = darkBlue;
export const defaultFontColorTurquoise = turquoise;
export const defaultFontErrorsColorOrange = orange;
export const defaultFontColorLightYellow = lightYellow;
export const defaultFontColorGreen = green;
export const defaultFontColorDarkGreen = darkGreen;
export const defaultFontColorLightPink = pink;

// default button colors
export const defaultBtColorDarkBlue = darkBlue;
export const defaultBtColorDarkGreen = darkGreen;
export const defaultBtColorTurquoise = turquoise;

// default icon colors
export const defaultIconColorTurquoise = turquoise;
export const defaultIconColorTurquoise2 = turquoise2;

// default radiuses
export const defaultCheckboxBorderRadius = '2px';
export const defaultBtBorderRadius = '5px';
export const defaultModalsBorderRadius = '10px';

// default border-colors
export const defaultBorderColorTurquoise = turquoise;
export const defaultBorderColorTurquoise2 = turquoise2;
export const defaultBorderColorDarkBlue = darkBlue;
export const defaultBorderColorLightRed = lightRed;
export const defaultBorderColorRed = red;

// flex
export const flexDirectionColumn = 'column';

export const flexCenterAlignments = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
