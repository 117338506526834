import DefaultBugsnag from '@bugsnag/js';

// Config
import config from '~/configs/local';
// Mocks
import BugsnagMock from './localMocks/bugsnag';

console.log(config);
const containerValues = {
  config,
  testStagingService: 'testStagingService',
  Bugsnag: config.TRACKERS.bugsnag ? DefaultBugsnag : BugsnagMock,
};

// add services to Container example:
// containerValues.TestService = new TestService(containerValues);

export default containerValues;
