import lodash from 'lodash';

const changeObjectProps = (obj) => {
  lodash.forIn(obj, function (value, key) {
    if (lodash.isObject(value)) {
      return changeObjectProps(value);
    }
    if (key === 'isLoading') {
      // eslint-disable-next-line no-param-reassign
      obj[key] = false;
    }
    return key;
  });
  return obj;
};

const isEmptyObj = (obj) => {
  if (!obj) throw new Error('isEmptyObj: Wrong param');
  return lodash.isEmpty(obj);
};

export default { changeObjectProps, isEmptyObj };
