import Joi from 'joi';

export const uuid = Joi.string().guid({
  version: [
    'uuidv4',
    // 'uuidv5'
  ],
});
export const firstName = Joi.string().min(2).max(30).required();
export const lastName = Joi.string().min(2).max(30).required();
export const email = Joi.string()
  .email({ tlds: { allow: false } })
  .message('Incorrect email');
export const password = Joi.string()
  .length(5)
  .required({ message: 'should consist of 5 characters' });
export const recoveryCode = Joi.string()
  .length(5)
  .required({ message: 'should consist of 5 characters' });
export const rememberMe = Joi.boolean();
export const termsAndConditions = Joi.boolean()
  .invalid(false)
  .required({ message: 'Check Terms and Conditions' })
  .messages({
    'any.invalid': 'Check Terms and Conditions',
  });
