import Constants from '~/utils/constants';

const { cacheKeys } = Constants;

export default {
  serverURL: 'http://localhost:3001', // TODO: during tests running local config isn't applied only default - check the reason and set-up correctly
  REQUESTS_TIME_OUT: {
    main: 10000, // 0ms - no timeout; https://github.com/axios/axios#request-config,
  },
  TRACKERS: {
    bugsnag: true,
    ga: true, // Google Analytics
  },
  CACHE_TTL: {
    [cacheKeys.commonRequests]: 10, // seconds
    [cacheKeys.toastersDuplication]: 5, // seconds
    [cacheKeys.inputErrors]: 3, // seconds
  },
};
