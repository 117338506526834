import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContainerContext } from '~/ServiceContainer';

import usePrevious from '~/hooks/usePrevious';

import { PasscodeDot } from '~/styledComponents/atoms';
import { SlowDown } from '~/styledComponents/effects/SlowOnset';

const dots = [1, 2, 3, 4, 5];

/**
 * PasscodeDots
 * @property {array} passcode
 * @property {boolean} isBlink
 * @property {function} handleFadeAnimationEnd
 */
const PasscodeDots = memo(({ passcode, isBlink, handleFadeAnimationEnd }) => {
  const { UUID } = useContext(ContainerContext);

  const prevPasscode = usePrevious(passcode);
  const isFade = prevPasscode?.length < passcode.length;
  return (
    <Wrapper>
      <SlowDown
        isBlink={isBlink}
        color="red"
        timing="2s"
        borderRadius="10px"
        onAnimationEnd={handleFadeAnimationEnd}
      >
        <Block>
          {dots.map((dot, i) => (
            <PasscodeDot
              key={UUID.v4()}
              isFade={isFade && i === passcode.length - 1}
              isFill={i <= passcode.length - 1}
            />
          ))}
        </Block>
      </SlowDown>
    </Wrapper>
  );
});
PasscodeDots.propTypes = {
  passcode: PropTypes.arrayOf(PropTypes.string),
  isBlink: PropTypes.bool,
  handleFadeAnimationEnd: PropTypes.func,
};
PasscodeDots.defaultProps = {
  passcode: [],
  isBlink: false,
  handleFadeAnimationEnd: () => {},
};

export default PasscodeDots;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px 30px;
  border-radius: 10px;
`;

const Block = styled.div`
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
