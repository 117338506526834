import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { defaultFontColorTurquoise } from '../mainConstants';

const Wrapper = styled.div`
  position: ${({ position }) => position};
  top: 25%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: ${defaultFontColorTurquoise};
  font-family: Helvetica;
  font-size: 34px;
  font-weight: bold;
  -moz-box-sizing: border-box; /* Для Firefox */
  box-sizing: border-box; /* Ширина блока с полями */

  @media (min-height: 800px) {
    position: absolute;
  }
`;

const Logo = ({ position, text }) => <Wrapper position={position}>{text}</Wrapper>;
Logo.propTypes = {
  position: PropTypes.string,
  text: PropTypes.string,
};
Logo.defaultProps = {
  position: 'inherit',
  text: 'Visiters',
};

export default Logo;
