import { createRoutine } from 'redux-saga-routines';

import { put } from 'redux-saga/effects';

export const setNotification = createRoutine('SET_NOTIFICATION');

/**
 * TODO: check or refactor notification usage
 * setNotification - redux action(saga-routines), set data to redux store
 * @param {Object} notification
 * @param {boolean} notification.active
 * @param {number} notification.type examples: 0 - Undo | 1 - Error | 2 - InputError | 3 - Warning | 4 - Success | 5 - Info.
 * @param {number} notification.displayType examples: 1 - System | 2 - Toaster | 3 - Snackbar | 4 - Tooltip
 * @param {number} notification.priority examples: 1 - high || 2 - low
 * @param {string} notification.layout examples: logo || action || logoAction
 * @param {string} notification.title examples: 'Error'
 * @param {string} [notification.description] examples: 'Best description in the world'
 * @param {number} [notification.order=0] examples: 0, 1, 2...
 * @default 0
 * @param {number} [notification.displayingTime] examples: 1, 2, 3 ... in seconds
 * @default 3
 * @param {number} [notification.timestamp]
 * @default unix_timestamp
 * @param {string} [notification.btnText] examples: 'Any'
 * @default 'Ok'
 * @param {function} [notification.btnOnPress] examples: () => {}
 * @default closeNotification
 */

export function* sagaOnSetNotification(props) {
  try {
    const { active, type, title, description } = props.payload;
    if (active && (!type || !title)) {
      throw new Error({
        message: 'Wrong params in sagaOnSetNotification',
      });
    }
    yield put(
      setNotification.success({
        active,
        type,
        title,
        description: description ?? null,
      })
    );
  } catch (e) {
    // handling error
  }
}
