import React, { memo, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import { ContainerContext } from '~/ServiceContainer';

import { ModalWrapper, ModalBackground } from '~/styledComponents/Modals';

import { defaultFontColorTurquoise } from '~/styledComponents/mainConstants';
import { LoaderWrapper } from '~/styledComponents/wrappers/Wrappers';

const LoadingModal = memo(({ type, closeModal, color, size }) => {
  const {
    spinners: { MoonLoader, FadeLoader, ClockLoader },
  } = useContext(ContainerContext);

  const renderLoader = useCallback(() => {
    switch (type) {
      case 'FadeLoader':
        return <FadeLoader color={color} size={size} />;
      case 'ClockLoader':
        return <ClockLoader color={color} size={size} />;
      default:
        return <MoonLoader color={color} size={size} />;
    }
  }, [type, color, size]);

  return (
    <ModalWrapper onClick={closeModal}>
      <ModalBackground />
      <LoaderWrapper size={size}>{renderLoader()}</LoaderWrapper>
    </ModalWrapper>
  );
});

LoadingModal.propTypes = {
  closeModal: PropTypes.func,
  type: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

LoadingModal.defaultProps = {
  closeModal: () => {},
  type: 'common',
  color: defaultFontColorTurquoise,
  size: 100,
};

export default LoadingModal;
