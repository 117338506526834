import React, { Suspense, lazy } from 'react';
import { Navigate, BrowserRouter, Routes, Route } from 'react-router-dom';

import { ContainerValues } from '~/ServiceContainer';

import RequireAuth from '~/hocs/RequireAuth';

// public
const LoginEmail = lazy(() => import('~/screens/Login/LoginEmail'));
const LoginPasscode = lazy(() => import('~/screens/Login/LoginPasscode'));
const ForgotPasscodeEmail = lazy(() => import('~/screens/ForgotPasscode/ForgotPasscodeEmail'));
const OnBoarding = lazy(() => import('~/screens/OnBoarding/OnBoarding'));
const SignUpInfo = lazy(() => import('~/screens/SignUp/SignUpInfo'));
const SignUpPasscode = lazy(() => import('~/screens/SignUp/SignUpPasscode'));
// protected
const Home = lazy(() => import('~/screens/protected/Home/Home'));
const MySpots = lazy(() => import('~/screens/protected/MySpots/MySpots'));
const AllSpots = lazy(() => import('~/screens/protected/AllSpots/AllSpots'));

const protectRoute = (children) => <RequireAuth>{children}</RequireAuth>;

const {
  routes: {
    onboarding,
    loginEmail,
    loginPasscode,
    signUpInfo,
    signUpPasscode,
    home,
    mySpots,
    allSpots,
    forgotPasscodeEmail,
  },
} = ContainerValues;

const Navigation = () => (
  <BrowserRouter>
    <Suspense fallback={<div>Загрузка...</div>}>
      <Routes>
        <Route index path={onboarding} element={<OnBoarding />} />
        <Route path={loginEmail} element={<LoginEmail />} />
        <Route path={loginPasscode} element={<LoginPasscode />} />
        <Route path={forgotPasscodeEmail} element={<ForgotPasscodeEmail />} />
        <Route path={signUpInfo} element={<SignUpInfo />} />
        <Route path={signUpPasscode} element={<SignUpPasscode />} />
        <Route path={home} element={protectRoute(<Home />)} />
        <Route path={mySpots} element={protectRoute(<MySpots />)} />
        <Route path={allSpots} element={protectRoute(<AllSpots />)} />
        <Route path="*" element={<Navigate to={onboarding} />} />
        {/* 
          <Route path="/" element={<App />}>
            <Route index element={<Home />} />
            <Route path="teams" element={<Teams />}>
              <Route path=":teamId" element={<Team />} />
              <Route path="new" element={<NewTeamForm />} />
              <Route index element={<LeagueStandings />} />
            </Route>
          </Route>
          <Route element={<PageLayout />}>
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/tos" element={<Tos />} />
        </Route>
        <Route path="contact-us" element={<Contact />} /> */}
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default Navigation;
