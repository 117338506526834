import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import Navigation from '~/navigation';

import { ScreenWrapper } from '~/styledComponents/wrappers/Wrappers';
import { SlowOnset } from '~/styledComponents/effects/SlowOnset';
import './App.css';

import useWindowListenersOnlineOffline from '~/hooks/useWindowListenersOnlineOffline';
import useNotificationsWatcher from '~/hooks/useNotificationsWatcher';

import { ModalsPortal, LoadingModal } from '~/components';

function App() {
  const { displayToaster } = useNotificationsWatcher();
  const { isOffline } = useWindowListenersOnlineOffline();
  console.log('App isOffline', isOffline);

  const storeNotifications = useSelector((store) => store.notifications, shallowEqual);

  const { isLoading, timestamp: isLoadingTimestamp } = useSelector(
    (store) => store.loading,
    shallowEqual
  );

  useEffect(() => {
    displayToaster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeNotifications]);

  console.log('App loading', isLoading, isLoadingTimestamp);
  return (
    <ScreenWrapper>
      <Navigation />
      {isLoading && (
        <ModalsPortal
          modal={
            <SlowOnset timing="0.5s">
              <LoadingModal type="ClockLoader" />
            </SlowOnset>
          }
        />
      )}
    </ScreenWrapper>
  );
}

export default App;
