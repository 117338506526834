import styled, { keyframes } from 'styled-components';
import { flexCenterAlignments } from '../mainConstants';

const blinkingEffect = (noBlink) => () => {
  if (noBlink) return '';
  return keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;
};

/**
 * SlowOnset
 * @param {boolean} noBlink - no animation effect.
 * @default true
 * @param {string} timing - animation duration.
 * @default '1s'
 * @param {string} type - animation-timing-function
 * @default 'linear'
 */
export const SlowOnset = styled.div`
  ${flexCenterAlignments}
  flex-direction: column;
  -webkit-animation: ${({ noBlink }) => blinkingEffect(noBlink)}
    ${({ noBlink, timing, type = 'linear' }) => (noBlink ? 'none ' : `${timing || '1s'} ${type}`)};
  -moz-animation: ${({ noBlink }) => blinkingEffect(noBlink)}
    ${({ noBlink, timing, type = 'linear' }) => (noBlink ? 'none ' : `${timing || '1s'} ${type}`)};
  -o-animation: ${({ noBlink }) => blinkingEffect(noBlink)}
    ${({ noBlink, timing, type = 'linear' }) => (noBlink ? 'none ' : `${timing || '1s'} ${type}`)};
  animation: ${({ noBlink }) => blinkingEffect(noBlink)}
    ${({ noBlink, timing, type = 'linear' }) => (noBlink ? 'none ' : `${timing || '1s'} ${type}`)};
`;

const downEffect = (isBlink, color) => () => {
  if (isBlink) {
    return keyframes`
      from {
        background-color: ${color};
      }
      to {
        background-color: transparent;
      }
    `;
  }
  return '';
};

/**
 * SlowDown
 * @param {boolean} isBlink - no animation effect.
 * @default true
 * @param {string} timing - animation duration.
 * @default '1s'
 * @param {string} type - animation-timing-function
 * @default 'linear'
 */
export const SlowDown = styled.div`
  display: flex;
  border-radius: ${({ borderRadius }) => borderRadius};
  -webkit-animation: ${({ isBlink, color }) => downEffect(isBlink, color)}
    ${({ isBlink, timing, type = 'linear' }) => (isBlink ? `${timing || '1s'} ${type}` : 'none')};
  -moz-animation: ${({ isBlink, color }) => downEffect(isBlink, color)}
    ${({ isBlink, timing, type = 'linear' }) => (isBlink ? `${timing || '1s'} ${type}` : 'none')};
  -o-animation: ${({ isBlink, color }) => downEffect(isBlink, color)}
    ${({ isBlink, timing, type = 'linear' }) => (isBlink ? `${timing || '1s'} ${type}` : 'none')};
  animation: ${({ isBlink, color }) => downEffect(isBlink, color)}
    ${({ isBlink, timing, type = 'linear' }) => (isBlink ? `${timing || '1s'} ${type}` : 'none')};
`;
