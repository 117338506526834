import { handleActions } from 'redux-actions';

import { ContainerValues } from '~/ServiceContainer';

import { setLoading } from '~/redux/actions/loading/loading.action';

const {
  helpers: { DateHelper },
} = ContainerValues;

export const initialState = {
  isLoading: false, // true | false
  type: 'common', // type: string, value: common | ?
  timestamp: null, // unix timestamp
};

export default handleActions(
  {
    [setLoading.SUCCESS](state, action) {
      const { isLoading, type } = action.payload;
      return {
        isLoading,
        type: type || 'common',
        timestamp: DateHelper.getUnixTimestamp(),
      };
    },
    [setLoading.FAILURE](state) {
      return {
        ...state,
        isLoading: false,
        timestamp: DateHelper.getUnixTimestamp(),
      };
    },
  },
  initialState
);

// For using >1 data object use like this:
// import { combineReducers } from 'redux';
// export default combineReducers({
//   data,
// });
