import Constants from '~/utils/constants';

const { cacheKeys } = Constants;

export default {
  REQUESTS_TIME_OUT: {
    main: 10000, // 0ms - no timeout; https://github.com/axios/axios#request-config,
  },
  TRACKERS: {
    bugsnag: false,
    ga: false, // Google Analytics
  },
  CACHE_TTL: {
    [cacheKeys.commonRequests]: 5, // seconds
    [cacheKeys.toastersDuplication]: 3, // seconds
    [cacheKeys.inputErrors]: 3, // seconds
  },
};
