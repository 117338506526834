import { ContainerValues } from '~/ServiceContainer';
import API from '~/api/api';

const {
  AxiosService: { fetch, setHeader },
} = ContainerValues;

const { user } = API;

export const loginRequest = ({ body }) => fetch('post', user.login, body);

export const signupRequest = ({ body }) => fetch('post', user.signup, body);

export const deleteRequest = ({ body, userId }) =>
  fetch('delete', `${user.logout}/${userId}`, body);

export const logoutRequest = ({ userId }) => fetch('post', `${user.logout}/${userId}`);

export const resetPasswordRequest = ({ body }) => fetch('put', user.resetPassword, body);

export const forgotPasswordRequest = ({ body }) => fetch('post', user.forgotPassword, body);

export const restorePasswordRequest = ({ restoreToken, body = {} }) => {
  setHeader(restoreToken, 'x-rtkn');
  return fetch('put', user.restorePassword, body);
};

export const checkEmailRequest = ({ body }) => fetch('post')(user.checkEmail, body);
