// languages
const nameUkr = 'Ukraine';
const shortNameUkr = 'ukr';
const nameEng = 'English';
const shortNameEng = 'eng';
const nameRus = 'Russian';
const shortNameRus = 'rus';
// toasters
const info = 'Info';
const warning = 'Warning';
const error = 'Error';
const success = 'Success';
const inputError = 'InputError';
const undo = 'Undo';
const defaultToastersPositioning = {
  vertical: 'top',
  horizontal: 'right',
};
const defaultToastersAutoHideDuration = 3500;
// errors
const internetConnectionError = 'internetConnection';
const passcodeNotConfirmed = 'passcodeNotConfirmed';

export default {
  defaultPage: 'Home',
  defaultLanguage: { name: nameUkr, shortName: shortNameUkr },
  https: {
    xTkn: 'x-tkn',
    xRTkn: 'x-rtkn',
    requestIdentifier: 'x-http-identifier',
  },
  cacheKeys: {
    commonRequests: 'commonRequests',
    toastersDuplication: 'toastersDuplication',
    inputErrors: 'inputErrors',
  },
  languages: [
    {
      name: nameUkr,
      shortName: shortNameUkr,
    },
    { name: nameEng, shortName: shortNameEng },
    { name: nameRus, shortName: shortNameRus },
  ],
  defaultErrorMessage: 'Something bad happened :(',
  defaultErrorInternet: 'No internet connection',
  localStorageKeys: {
    store: 'store',
  },
  toasters: {
    typeInfo: {
      name: info,
      positioning: defaultToastersPositioning,
      autoHideDuration: defaultToastersAutoHideDuration,
      persist: false,
    },
    typeWarning: {
      name: warning,
      positioning: defaultToastersPositioning,
      autoHideDuration: defaultToastersAutoHideDuration,
      persist: false,
    },
    typeError: {
      name: error,
      positioning: defaultToastersPositioning,
      autoHideDuration: defaultToastersAutoHideDuration,
      persist: false,
    },
    typeSuccess: {
      name: success,
      positioning: defaultToastersPositioning,
      autoHideDuration: defaultToastersAutoHideDuration,
      persist: false,
    },
    typeInputError: {
      name: inputError,
      positioning: {
        vertical: 'top',
        horizontal: 'left',
      },
      autoHideDuration: defaultToastersAutoHideDuration,
      persist: true,
    },
    typeUndo: {
      name: undo,
      positioning: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      autoHideDuration: defaultToastersAutoHideDuration,
      persist: false,
    },
  },
  errorsIds: {
    internetConnection: internetConnectionError,
  },
  translator: {
    [shortNameEng]: {
      errorMessages: {
        defaultErrorMessage: 'Something bad happened :(',
        internetConnection: {
          id: internetConnectionError,
          title: 'No internet connection',
          description: 'Please check your connectivity',
        },
        passcodeNotConfirmed: {
          id: passcodeNotConfirmed,
          title: 'Wrong Confirmation Passcode',
        },
      },
    },
    [shortNameUkr]: {
      errorMessages: {
        defaultErrorMessage: 'Cталось шось недобре :(',
        internetConnection: {
          id: internetConnectionError,
          title: 'Немає підключення до Інтернету',
          description: 'Будь ласка, перевірте підключення',
        },
        passcodeNotConfirmed: {
          id: passcodeNotConfirmed,
          title: 'Пароль не підтверджено',
        },
      },
    },
    [shortNameRus]: {
      errorMessages: {
        defaultErrorMessage: 'Случилось чтото не ладное :(',
        internetConnection: {
          id: internetConnectionError,
          title: 'Нет соединения с интернетом',
          description: 'Пожалуйста, проверьте подключение',
        },
        passcodeNotConfirmed: {
          id: passcodeNotConfirmed,
          title: 'Пароль не подтвержден',
        },
      },
    },
  },
};
